import React, { useCallback, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import Modal from '~/components/.base/modal';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { Container, FlexBox } from '../components/.base/containers';
import { HeadingLarge, Heading, BodyText } from '~/components/.base/headings';
import { WhiteButton, RedButton } from '~/components/.base/buttons';
import { breakpoint } from '../constants/Breakpoint';
import Video from '../components/.base/video';
import { WoodenBorder } from '../components/.base/border';
import MailingListPopup from '../components/JoinMailingList/MailingListPopup';

const RecipesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px 20px;
    @media ${breakpoint.lg} {
        grid-gap: 50px 40px;
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${breakpoint.xxl} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const Recipes = ({ data, location }) => {
    const { title, contentModules } = data.contentfulPage;
    const [modalIndex, setModalIndex] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const section = contentModules.find(
        m => m.__typename === 'ContentfulEcommercePageRecipeSection'
    );
    const validRecipes = useMemo(
        () => section?.recipes?.filter(item => !!item?.videoLink),
        [section]
    );
    const handlePreviousModal = useCallback(() => {
        setModalIndex(idx => {
            if (idx === 0 && validRecipes.length > 0) {
                return validRecipes.length - 1;
            } else {
                return idx - 1;
            }
        });
    }, [setModalIndex, validRecipes]);
    const handleNextModal = useCallback(() => {
        setModalIndex(idx => {
            if (idx === validRecipes.length - 1) {
                return 0;
            } else {
                return idx + 1;
            }
        });
    }, [setModalIndex, validRecipes]);
    return (
        <>
            <MailingListPopup />
            <SEO title={title} />
            <Criteo />
            <DeriveHeaderClasses location={location} />
            <div
                css={css`
                    .detail-hero .hero-container .hero-details {
                        top: 130px;
                    }
                `}
            >
                <div
                    className="detail-hero"
                    css={css`
                        .hero-container {
                            padding-top: 186px;
                            margin-bottom: 0;
                            @media ${breakpoint.sm} {
                                padding-top: 117px;
                                min-height: 400px;
                            }
                        }
                    `}
                >
                    <div
                        className="hero-container"
                        style={{
                            backgroundImage: `url('${
                                section.backgroundImage ? section.backgroundImage.fixed.src : ''
                            }')`,

                            backgroundSize: 'cover',
                            backgroundColor: '#000',
                            height: 'auto',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center top',
                        }}
                        css={css`
                            background-position: center;
                            @media ${breakpoint.maxMd} {
                                background-size: 275% !important;
                            }
                        `}
                    >
                        <div className="hero-details">
                            <Container>
                                <HeadingLarge
                                    css={css`
                                        margin-bottom: 25px;
                                        @media ${breakpoint.lg} {
                                            margin-bottom: 10px;
                                        }
                                    `}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: section.heading,
                                        }}
                                    ></span>
                                </HeadingLarge>
                                <BodyText
                                    css={css`
                                        color: #fff;
                                        text-align: center;
                                    `}
                                >
                                    {section.description.description}
                                </BodyText>
                            </Container>
                        </div>
                        <WoodenBorder top="100%" />
                    </div>
                </div>

                <section
                    css={css`
                        position: relative;
                        z-index: 3;
                        margin-top: 60px;
                        margin-bottom: 45px;
                        @media ${breakpoint.lg} {
                            margin-top: -60px;
                            margin-bottom: 150px;
                        }
                    `}
                >
                    <Container>
                        <RecipesGrid>
                            {validRecipes.map((recipe, i) => (
                                <Video
                                    key={i}
                                    index={i}
                                    video={recipe}
                                    setModalIsOpen={setModalIsOpen}
                                    setModalIndex={setModalIndex}
                                />
                            ))}
                        </RecipesGrid>
                    </Container>
                </section>
            </div>
            <Modal
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                ariaLabel={validRecipes[modalIndex].title || `Recipe ${modalIndex + 1}`}
                modalMaxWidth="650px"
                top="0"
            >
                <div
                    css={css`
                        .recipe-modal-header {
                            border-radius: 30px;
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                            box-shadow: 0 1px 0 0 #c2cbcc;
                            background-color: #f4f7f7;
                            padding: 24px 30px;
                        }
                        .recipe-modal-body {
                            padding: 35px 30px;
                            min-height: 350px;
                            .icon-reversal-cont {
                                position: absolute;
                                width: 84px;
                                height: 84px;
                                background-color: #d4212c;
                                border-radius: 50%;
                                margin-right: 1rem;
                                flex-grow: 1;
                                svg {
                                    position: absolute;
                                    left: 26px;
                                    top: 26px;
                                    g {
                                        stroke: #fff;
                                    }
                                    &.uses-fill g {
                                        stroke: transparent;
                                        fill: #fff;
                                    }
                                }
                            }
                            iframe {
                                height: 332px;
                            }
                        }
                        .recipe-modal-footer {
                            border-radius: 30px;
                            border-top-left-radius: 0px;
                            border-top-right-radius: 0px;
                            box-shadow: 0 -1px 0 0 #c2cbcc;
                            background-color: #f4f7f7;
                            padding: 24px 30px;
                            button {
                                margin-left: 1rem;
                            }
                        }
                        @media screen and (max-width: 1024px) {
                            height: 100%;
                            .recipe-modal-header {
                                padding-right: 80px;
                            }
                            .recipe-modal-body {
                                height: calc(100% - 104px - 88px);
                                padding: 20px 5px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                    `}
                >
                    <div className="recipe-modal-header">
                        <Heading>{validRecipes[modalIndex].title}</Heading>
                    </div>
                    <div className="recipe-modal-body">
                        <iframe
                            width="100%"
                            title={validRecipes[modalIndex].title || `Recipe ${modalIndex + 1}`}
                            src={`${validRecipes[modalIndex].videoLink}?autoplay=1`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="recipe-modal-footer">
                        <FlexBox justify="flex-end">
                            <WhiteButton onClick={handlePreviousModal}>Previous</WhiteButton>
                            <RedButton onClick={handleNextModal}>Next</RedButton>
                        </FlexBox>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export const query = graphql`
    query {
        contentfulPage(slug: { eq: "recipes" }) {
            title
            contentModules {
                ... on ContentfulEcommercePageRecipeSection {
                    heading
                    description {
                        description
                    }
                    backgroundImage {
                        fixed(width: 1600) {
                            src
                        }
                    }
                    recipes {
                        title
                        poster {
                            description
                            fluid(maxWidth: 800) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                        }
                        videoLink
                    }
                }
            }
        }
    }
`;

export default Recipes;
